'use client'
import React, { useEffect } from 'react'
import { GoogleTagManager } from '@next/third-parties/google'
import { onDatagrailMount } from './lib'

/**
 * Datagrail has its own logic for determining whether consent has been configured based on IP address.
 * However, we need a bit more granularity to determine whether the user has set preferences.
 */

type ConsentManagerProps = {
	gtmId: string
}
export const ConsentManager = ({ gtmId }: ConsentManagerProps) => {
	const STYLE_ID = 'dg-consent-custom-style'

	useEffect(() => {
		const cleanupFn = onDatagrailMount(() => {
			const styleNode = document.createElement('style')
			styleNode.id = STYLE_ID
			styleNode.innerHTML = `
		  /*
			Hack to provide custom styles to the consent banner.
			This method is necessary since the consent banner is loaded in a shadow DOM.
			docs: https://docs.datagrail.io/docs/consent/consent-banner-customization
		  */
			:host(.dg-consent-banner) .dg-main-content {
				overflow-y: auto;
			}
			.language-dropdown-menu {
				overflow: auto;
			}
			`
			document.head.appendChild(styleNode)
		})

		return () => {
			cleanupFn()
			const styleNode = document.getElementById(STYLE_ID)
			if (styleNode) {
				styleNode.remove()
			}
		}
	}, [])

	return <GoogleTagManager gtmId={gtmId} />
}
