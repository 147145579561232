import { defineRouting, type LocalePrefixMode } from 'next-intl/routing'
import { createNavigation } from 'next-intl/navigation'

export const routingConfig = {
	// A list of all locales that are supported
	locales: ['en', 'ja', 'de', 'fr', 'ko', 'pt', 'es'],

	// Used when no locale matches
	defaultLocale: 'en',
}

export const routing = defineRouting(routingConfig)

// Lightweight wrappers around Next.js' navigation APIs
// that will consider the routing configuration
export const { Link, redirect, usePathname, useRouter } =
	createNavigation(routing)
