import { DatagrailEvent, DatagrailPreferencesPayload } from './types'

export const isOnClient = () => typeof window !== 'undefined'
export const datagrailHasMounted = () =>
	isOnClient() && window.hasOwnProperty('DG_BANNER_API')

export const showBanner = () => {
	if (!datagrailHasMounted()) return
	window.DG_BANNER_API.showConsentBanner()
}

/**
 * Adds an event listener for the `initial_preference_callback` and `preference_callback` events and executes
 * a callback when the event is triggered. See DataGrail docs here:
 * https://docs.datagrail.io/docs/consent/retrieve-consent-choices
 *
 * This function serves to retrieve a user's consent choices either at page load or when the user changes their
 * prefernces. The callback will be invoked with the provided preferences payload.
 *
 * @param {DatagrailEvent} event - The DataGrail event to listen for.
 * @param {(preferences: DatagrailPreferencesPayload) => void} callback - The callback function to execute
 * when the event is triggered. It receives a preferences payload containing the user's consent preferences.
 *
 * @returns {void}
 */

export const addDatagrailEventListener = (
	event: DatagrailEvent,
	callback: (preferences: DatagrailPreferencesPayload) => void
) => {
	if (!isOnClient()) return

	window.dgEvent = window.dgEvent || []
	window.dgEvent.push({ event, params: callback })
}

// no need to remove individual listeners because the array is reset on mount
// ie we can just reset the array
export const removeDatagrailEventListeners = () => {
	if (!isOnClient()) return
	window.dgEvent = []
}

const POLL_INTERVAL_MS = 100
const MOUNT_TIMEOUT_MS = 5000

export const onDatagrailMount = (callback: () => void): (() => void) => {
	if (!isOnClient()) return
	if (datagrailHasMounted()) {
		callback()
		return () => removeDatagrailEventListeners()
	}

	const intervalId = setInterval(() => {
		if (datagrailHasMounted()) {
			callback()
			cleanup()
		}
	}, POLL_INTERVAL_MS)

	const timeoutId = window.setTimeout(() => {
		console.error(
			'[@web/consent-manager]: Timeout exceeded. Failed to mount consent banner'
		)
		cleanup()
	}, MOUNT_TIMEOUT_MS)

	function cleanup() {
		clearInterval(intervalId)
		window.clearTimeout(timeoutId)
	}

	return cleanup
}

export const GTM_CONTAINERS = {
	WWW: 'GTM-NRFH7Z9',
	BOUNDARY_IO: 'GTM-5G2M3PX',
	PACKER_IO: 'GTM-MBQLDJ4',
	NOMAD_IO: 'GTM-WD855VW',
	WAYPOINT_IO: 'GTM-T5PZBF2',
	VAGRANT_IO: 'GTM-KBG357M',
	VAULT_IO: 'GTM-MNZVNLR',
	TERRAFORM_IO: 'GTM-N2S5JT5',
	CONSUL_IO: 'GTM-5H7H4MX',
	WORKS: 'GTM-TQMWRGT',
	I18N: 'GTM-KSZMZKPZ',
} as const
