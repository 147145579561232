import { showBanner } from './lib'
import { ConsentManager } from './component'

const createConsentManager = (gtmId: string) => {
	if (!gtmId) {
		throw new Error(`@web/consent-manager: \`gtmId\` is required.
			Get the relevant Google Tag Manager Container ID`)
	}

	return {
		open: showBanner,
		ConsentManager: () => <ConsentManager gtmId={gtmId} />,
	}
}

export { createConsentManager, ConsentManager }
export * from './lib'
