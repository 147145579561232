import(/* webpackMode: "eager", webpackExports: ["AppRouterAppGlobals"] */ "/vercel/path0/apps/www/components/app-globals/index.ts");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next-intl/dist/esm/navigation/shared/BaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next-intl/dist/esm/navigation/shared/LegacyBaseLink.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/node_modules/next-intl/dist/esm/shared/NextIntlClientProvider.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/local/target.css?{\"path\":\"../../packages/global-styles/src/font/hashicorp-sans.ts\",\"import\":\"\",\"arguments\":[{\"src\":\"./HashiCorp_Sans_Variable.woff2\",\"display\":\"swap\",\"variable\":\"--hashicorp-sans\",\"weight\":\"300 600\"}],\"variableName\":\"hashicorpSans\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/packages/global-styles/src/font/style.module.css");
