'use client'
import { useEffect } from 'react'
import classNames from 'classnames'
import s from './styles.module.css'
import {
	createConsentManager,
	GTM_CONTAINERS,
	addDatagrailEventListener,
	removeDatagrailEventListeners,
} from '@web/consent-manager'
import sendPosthogEvent from 'lib/posthog/send-posthog-event'

const { ConsentManager, open: openConsentManager } = createConsentManager(
	GTM_CONTAINERS.WWW
)

const POSTHOG_EVENT_NAME = 'Datagrail Consent Preferences'

/**
 * Datagrail preferences cookie options are stored in an array. In order to surface each cookie option
 * as a property of a Posthog event, we transform the array into an object with the category name as the keys
 * and their enabled/disabled status as the values.
 *
 * @param {Array<{ gtm_key: string, isEnabled: boolean }>} cookieOptions - An array of cookie option objects.
 * Each object contains a `gtm_key` (string) and an `isEnabled` (boolean) field.
 *
 * @returns {Object<string, boolean> | undefined} An object where each key is a `gtm_key`
 * and the value is a boolean representing whether the corresponding option is enabled.
 * If the `cookieOptions` array is falsy or empty, returns `undefined`.
 */

export const transformCookieOptionsIntoPosthogPayload = (
	cookieOptions: {
		gtm_key: string
		isEnabled: boolean
	}[]
):
	| {
			[key: string]: boolean
	  }
	| undefined => {
	// If for whatever reason `cookieOptions` doesn't exist, return undefined.
	if (!cookieOptions) return undefined
	return cookieOptions.reduce((obj, item) => {
		obj[item.gtm_key] = item.isEnabled
		return obj
	}, {})
}

export const ConsentManagerButton = () => {
	useEffect(() => {
		addDatagrailEventListener('initial_preference_callback', (preferences) => {
			const payload = transformCookieOptionsIntoPosthogPayload(
				preferences?.consentPreferences?.cookieOptions
			)

			sendPosthogEvent(POSTHOG_EVENT_NAME, {
				datagrail_event: 'initial_preference_callback',
				...payload,
			})
		})
		addDatagrailEventListener('preference_callback', (preferences) => {
			const payload = transformCookieOptionsIntoPosthogPayload(
				preferences?.consentPreferences?.cookieOptions
			)
			sendPosthogEvent(POSTHOG_EVENT_NAME, {
				datagrail_event: 'preference_callback',
				...payload,
			})
		})

		return () => {
			removeDatagrailEventListeners()
		}
	}, [])

	return (
		<button
			className={classNames(s.link, s.cookieManager)}
			data-ga-footer="Bottom | Cookie Manager"
			onClick={openConsentManager}
		>
			Cookie Manager
		</button>
	)
}
export { ConsentManager }
